<template>
  <div class="register-user--view">
    <div class="form">
      <div class="container">
        <div class="header--container">
          <h1>Настройки профиля</h1>

        </div>
        <div class="input--container">
          <register-input header="ID/ номер телефона:" v-model="phone" id="phone"></register-input>
        </div>
        <div class="input--container">
          <register-input header="Имя аккаунта:" v-model="name"></register-input>

        </div>
        <div class="input--container">
          <register-input header="Адрес эл.почты:" v-model="email"></register-input>

        </div>
        <div class="input--container">
          <register-input header="Придумайте пароль:" v-model="password"></register-input>

        </div>
        <div class="icon--container">
          <img src="../../assets/icons/user.svg">
        </div>
      </div>
      <div class="container">
        <div class="select--container">
          <register-select header="Категория:" :values="['ШКОЛА','САДИК','БИЗНЕС']" v-model="type" :selected-value="type"></register-select>
        </div>
        <div class="input--container">
          <register-input header="Адрес:" v-model="address" ref="addressInput" :value="address"></register-input>
        </div>
        <div class="addition--container">
          <div class="avatar--container">
            <p>Аватарка:</p>
            <div class="avatar-inner--container">
              <input type="file" accept=".jpg,.jpeg,.png" name="avatar" class="avatar" @change="onChangeAvatar" ref="avatarLoad">
              <img class="user__avatar" ref="avatarImage" :class="{'avatar-visible' : isAvatarImageVisible}">
              <img class="user__avatar 1" :class="{'avatar-visible' : !isAvatarImageVisible}" src="../../assets/icons/plus.png">
            </div>
          </div>
          <div class="map--container" id="gmap_markers">

          </div>
        </div>
        <div class="button--container">
          <button class="btn btn_delete" @click="showDeleteAccountModal">Удалить профиль</button>
        </div>
        <div class="buttons--container">
          <button class="btn btn__close" @click="goBack">ОТМЕНА</button>
          <button class="btn btn__action"  :class="{'not_available' : false}" @click="register">ГОТОВО</button>
        </div>
      </div>
    </div>
    <cropper-image-modal v-on:close="onCloseCropper" v-on:crop="onCropAvatar" type="avatar"></cropper-image-modal>
    <delete-account-modal></delete-account-modal>
  </div>
</template>

<script>
import RegisterInput from "@/components/input/RegisterInput";
import RegisterSelect from "@/components/select/RegisterSelect";
import {Loader} from 'google-maps';
import CropperImageModal from "@/components/modal/CropperImageModal";
import IMask from "imask";
import DeleteAccountModal from "@/components/modal/DeleteAccountModal";


export default {
  name: "ChangeUserDataView",
  components: {DeleteAccountModal, CropperImageModal, RegisterSelect, RegisterInput},
  data(){
    return {
      phone: '',
      password: '',
      address: '',
      email: '',
      type: '',
      name: '',
      avatarBase64: '',
      lat: 0,
      lng: 0,
      map: {},
      geocoder: {},
      markers: [],
      isAvatarImageVisible: false
    }
  },
  computed:{
    user() {
      return this.$store.getters.SELECTED_USER
    },
  },
  methods:{
    async initMap () {
      try {
        const options = {libraries : ['places']}
        const loader = new Loader('AIzaSyCsPiDAbqTp_oxkpFVsJO032H-TpKWnTvI', options);
        this.google = await loader.load();

        const mapProp = {
          zoom: 14,
          disableDefaultUI: true,
          mapTypeId: this.google.maps.MapTypeId.ROADMAP,
          center: new this.google.maps.LatLng(55.732093, 37.739179),
          styles: [{featureType: 'water', elementType: 'geometry.fill', stylers: [{color: '#d3d3d3'}]}, {featureType: 'transit', stylers: [{color: '#808080'}, {visibility: 'off'}]}, {featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{visibility: 'on'}, {color: '#b3b3b3'}]}, {featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{color: '#ffffff'}]}, {featureType: 'road.local', elementType: 'geometry.fill', stylers: [{visibility: 'on'}, {color: '#ffffff'}, {weight: 1.8}]}, {featureType: 'road.local', elementType: 'geometry.stroke', stylers: [{color: '#d7d7d7'}]}, {featureType: 'poi', elementType: 'geometry.fill', stylers: [{visibility: 'on'}, {color: '#ebebeb'}]}, {featureType: 'administrative', elementType: 'geometry', stylers: [{color: '#a7a7a7'}]}, {featureType: 'road.arterial', elementType: 'geometry.fill', stylers: [{color: '#ffffff'}]}, {featureType: 'road.arterial', elementType: 'geometry.fill', stylers: [{color: '#ffffff'}]}, {featureType: 'landscape', elementType: 'geometry.fill', stylers: [{visibility: 'on'}, {color: '#efefef'}]}, {featureType: 'road', elementType: 'labels.text.fill', stylers: [{color: '#696969'}]}, {featureType: 'administrative', elementType: 'labels.text.fill', stylers: [{visibility: 'on'}, {color: '#737373'}]}, {featureType: 'poi', elementType: 'labels.icon', stylers: [{visibility: 'off'}]}, {featureType: 'poi', elementType: 'labels', stylers: [{visibility: 'off'}]}, {featureType: 'road.arterial', elementType: 'geometry.stroke', stylers: [{color: '#d6d6d6'}]}, {featureType: 'road', elementType: 'labels.icon', stylers: [{visibility: 'off'}]}, {featureType: 'poi', elementType: 'geometry.fill', stylers: [{color: '#dadada'}]}]

        }
        this.map = new this.google.maps.Map(document.getElementById('gmap_markers'), mapProp);

        this.geocoder = new this.google.maps.Geocoder()

        const input = this.$refs.addressInput.$el.getElementsByTagName("input")[0]
        const autocomplete = new this.google.maps.places.Autocomplete(input)

        this.google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace()
          this.address = place.formatted_address
          this.lat = place.geometry.location.lat()
          this.lng = place.geometry.location.lng()
          this.placeMarkerAndPanTo({'lat': this.lat, 'lng': this.lng})
        })

        this.map.addListener('click', (e) => this.placeMarkerAndPanTo({'lat': e.latLng.lat(), 'lng': e.latLng.lng()}))

        const yorPos = {lat: 55.75413771, lng: 37.62139542}

        this.map.setCenter(yorPos)
      } catch (e) {
        console.log(e)
      }
    },
    placeMarkerAndPanTo: function (latLng) {
      this.deleteMarkers()
      const marker = new this.google.maps.Marker({
        position: latLng,
        map: this.map,
        icon: '/img/map_marker.png'
      })
      this.markers.push(marker)
      this.map.panTo(latLng)
      this.addressTransform(latLng)
    },

    deleteMarkers: function () {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null)
      }
      this.markers = []
    },

    addressTransform: function (latLng) {
      this.geocoder.geocode({ 'location': latLng }, (results, status) => {
        if (status === 'OK') {
          let address = ''
          if (results[0]) {
            address = results[0].formatted_address
            this.address = address
            this.lat = latLng.lat
            this.lng = latLng.lng
          } else {
            window.alert('No results found')
          }
        } else {
          window.alert('Geocoder failed due to: ' + status)
        }
      })
    },
    onChangeAvatar (e) {
      const files = e.target.files || e.dataTransfer.files
      if (files.length) {
        this.createImage(files[0])
      }
    },

    createImage (file) {
      this.$modal.show('CropperImageModal', {value: file})
    },

    onCloseCropper () {
      this.$refs.avatarLoad.value = ''
    },

    onCropAvatar (src) {
      this.isAvatarImageVisible = true
      this.$refs.avatarImage.src = src
      this.avatarBase64 = src.split(',')[1]
    },

    async register(){

      const res = await this.$store.dispatch("CHANGE_PROFILE",{userId: this.$route.params.id,data: {
          phone: this.phone.substr(0, 16).replace(/[^a-zA-Z0-9]/g, ''),
          name: this.name,
          address: this.address,
          lat: this.lat,
          lng: this.lng,
          password: (this.password.length === 0) ? null : this.password,
          email: this.email,
          avatar: (this.avatarBase64.length === 0) ? null :this.avatarBase64
        }})

      if (res === 200){
        const userId = this.$route.params.id;
        this.$router.push({name : "UserView",params : {id : userId}})
      }
    },


    goBack(){
      const userId = this.$route.params.id;
      this.$router.push({name : "UserView",params : {id : userId}})
    },

    showDeleteAccountModal(){
      this.$modal.show("DeleteAccountModal")
    }
  },
  async mounted() {
    this.initMap()


    const maskOptions = {
      mask: '+{7}(000)000-00-00'
    }

    const userId = this.$route.params.id;
    await this.$store.dispatch("GET_SELECTED_USER", {userId: userId})
    document.getElementById("phone").value = this.user.phone
    this.mask = new IMask(document.getElementById("phone"), maskOptions)


    this.name = this.user.name
    this.email = this.user.email
    this.address = this.user.address
    if (this.user.type == 0){
      this.type = 2
    } else if (this.user.type == 1){
      this.type = 0
    } else if (this.user.type == 3){
      this.type = 1
    }
    this.phone = this.user.phone
    this.lat = this.user.lat
    this.lng = this.user.lng

    console.log(document.getElementById("phone"))
    this.mask.updateControl()
    this.mask.updateValue()




    this.placeMarkerAndPanTo({lat: this.lat,lng: this.lng})
  }

}
</script>

<style scoped lang="scss">
.register-user--view{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .form{
    width: 500px;
    height: 300px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
    border-radius: 4px;
    display: flex;

    @include for-big-desktop-up{
      width: 750px;
      height: 450px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.14);
      border-radius: 6px;
    }


    .container{
      display: flex;
      flex-direction: column;
      margin-top: 22px;

      @include for-desktop-up{
        margin-top: 15px;
      }

      .header--container{
        margin-bottom: 19px;

        @include for-desktop-up{
          margin-bottom: 13px;
        }

        h1{
          font-family: Roboto, "sans-serif";
          font-size: 18px;
          color: #2C2C2C;
          letter-spacing: 0;

          @include for-desktop-up{
            font-size: 12px;
          }
        }

        h2{
          font-family: Roboto, "sans-serif";
          font-size: 13px;
          color: #999999;
          letter-spacing: 0;
          margin-top: 5px;

          @include for-desktop-up{
            font-size: 7px;
            margin-top: 4px;
          }
        }
      }

      &:first-child{
        margin-left: 32px;

        @include for-desktop-up{
          margin-left: 24px;

        }
      }

      .input--container{
        margin-top: 16px;

        @include for-desktop-up{
          margin-top: 11px;

        }
      }

      .select--container{
        margin-bottom: 14px;

        @include for-desktop-up{
          margin-bottom: 5px;

        }
      }

      .icon--container{
        margin-top: 32px;

        @include for-desktop-up{
          margin-top: 22px;
        }

        img{
          width: 45px;
          height: 48px;

          @include for-desktop-up{
            width: 30px;
            height: 32px;
          }
        }
      }


      &:last-child{
        margin-left: 38px;

        @include for-desktop-up{
          margin-left: 26px;

        }
      }


      .addition--container{
        margin-top: 31px;
        display: flex;

        @include for-desktop-up{
          margin-top: 11px;
        }

        .map--container{
          width: 185px;
          height: 131px;
          border: 1px solid #979797;
          border-radius: 17px;
          background: #000000;
          margin-left: 89px;
          margin-top: 6px;

          @include for-desktop-up{
            width: 120px;
            height: 90px;
            margin-left: 60px;
            margin-top: 3px;
            border-radius: 12px;
          }
        }

        .avatar--container{
          margin-top: 4px;

          @include for-desktop-up{
            margin-top: 2px;
          }


          p{
            font-family: Roboto, "sans-serif";
            font-size: 12px;
            color: #2C2C2C;
            letter-spacing: 0;

            @include for-desktop-up{
              font-size: 8px;
            }
          }

          .avatar-inner--container{
            border: 1px solid #979797;
            border-radius: 6px;
            margin-top: 6px;
            width: 97px;
            height: 97px;


            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

            /* Rectangle Copy 8: */

            overflow: hidden;
            z-index: 1;


            @include for-desktop-up{
              border-radius: 4px;
              margin-top: 3px;
              width: 65px;
              height: 65px;
            }

            input{
              opacity: 0;
              position: absolute;
              width: 100%;
              left: 0;
              top:0;
              height: 97px;
              z-index: 3;


              @include for-desktop-up{
                height: 65px;
              }
            }

            .file__text{
              position: absolute;
              font-family: Roboto, sans-serif;
              font-size: 16px;
              letter-spacing: 0;
              font-weight: 300;
              color: rgba(255,255,255,0.5);
              margin: 0;
              padding: 0;
              z-index: 2;
            }

            .file__text.avatar-visible{
              display: none;
            }

            .user__avatar{
              width: 97px;
              height: 97px;
              margin: 0;
              padding: 0;
              display: none;
              object-fit: cover;


              @include for-desktop-up{
                height: 65px;
                width: 65px;
              }
            }

            .user__avatar.avatar-visible{
              display: flex;
            }

          }
        }
      }



      .buttons--container{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        @include for-big-desktop-up{
          margin-top: 20px;
        }


        .btn{
          width: 100px;
          height: 21px;
          background: #6F64F8;
          border-radius: 4px;
          font-family: Roboto, "sans-serif";
          font-size: 8px;
          letter-spacing: 0;
          border: 0;
          outline: 0;
          cursor: pointer;

          @include for-big-desktop-up{
            width: 150px;
            height: 32px;
            border-radius: 6px;
            font-size: 12px;
            letter-spacing: 0;
          }

          &:hover{
            filter: brightness(87.5%);
          }

          &.not_available{
            pointer-events: none;
            opacity: .5;
          }
        }

        .btn__action{
          background: #6F64F8;
          color: #FFFFFF;
        }

        .btn__close{
          background: #EDEDED;
          color: #2C2C2C;
          margin-right: 15px;

          @include for-big-desktop-up{
            margin-right: 25px;
          }
        }
      }



      .button--container{

        margin-top: 44px;
        display: flex;
        justify-content: flex-end;

        @include for-big-desktop-up{
          margin-top: 44px;
        }

        .btn{
          width: 100px;
          height: 21px;
          background: #6F64F8;
          border-radius: 4px;
          font-family: Roboto, "sans-serif";
          font-size: 8px;
          letter-spacing: 0;
          border: 0;
          outline: 0;
          cursor: pointer;

          @include for-big-desktop-up{
            width: 150px;
            height: 32px;
            border-radius: 6px;
            font-size: 12px;
            letter-spacing: 0;
          }

          &:hover{
            filter: brightness(87.5%);
          }

          &.not_available{
            pointer-events: none;
            opacity: .5;
          }
        }



        .btn_delete{
          background: #D45867;
          color: #FFFFFF;


          width: 195px;
          height: 32px;

          @include for-desktop-up{
            width: 130px;
            height: 21px;
          }
        }


      }
    }



  }
}




.disabled{
  pointer-events: none;
  opacity: .5;
}
</style>
