<template>
  <modal name="DeleteAccountModal" :width="width" :height="height">
    <div class="modal--view">
      <div class="modal--head">
        <a @click="$modal.hide('DeleteAccountModal')"><span class="icon-close"></span></a>
      </div>
      <div class="modal--body">
        <div class="title--container">
          <p>Вы уверены что хотите удалить аккаунт?</p>
        </div>
        <div class="button--container">
          <button class="close__button" @click="onDecline">ОТМЕНА</button>
          <button @click="onConfirm">ДА</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>

export default {
  name: 'DeleteAccountModal',
  computed:{
    width(){
      return "308px"
    },

    height(){
      return "150px"
    },
  },
  methods:{
    onClose(){
      this.$modal.hide("DeleteAccountModal");
    },

    onDecline(){
      this.onClose();
    },

    onConfirm(){
      this.onClose();
      this.$emit("confirm");
    }
  }
}

</script>

<style scoped lang="scss">
.modal--view{
  width: 308px;
  height: 150px;
  border-radius: 10px;
  background: #f9f9f9;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;


  .modal--head{
    margin-left: 282px;
    margin-top: 16px    ;
    a span{
      font-size: 10px;
    }
  }

  .modal--body{
    display: flex;
    flex-direction: column;

    .title--container{
      margin-left: 20px;
      margin-right: 16px;

      p{
        font-family: Roboto, "sans-serif";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 25px;
      }

    }

    .text--container{
      margin-left: 20px;
      margin-right: 16px;
      margin-top: 7px;
      p{
        font-family: Roboto, "sans-serif";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 300;
      }

    }

    .img--container{
      margin-top: 37px;

      img{
        width: 245px;
        height: 126px;
      }
    }

    .button--container{
      margin-top: 21px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;
      button{
        font-family: Roboto, "sans-serif";
        font-weight: 400;
        font-size: 12px;
        color: #CA4EBB;
        letter-spacing: 2px;
        text-align: center;
        cursor: pointer;
        border: 0;
        outline: 0;
        margin-right: 15px;
        padding-left: 21px;
        padding-right: 21px;
        height: 32px;
        border-radius: 8px;
        background-color: transparent;
        &:hover{
          background-color: #F1F1F1;
        }
      }

      .close__button{
        font-family: Roboto, "sans-serif";
        font-weight: 400;
        font-size: 12px;
        color: black;
        letter-spacing: 2px;
        text-align: center;
        cursor: pointer;
        border: 0;
        outline: 0;
        margin-right: 10px;


        padding-left: 21px;
        padding-right: 21px;
        height: 32px;
        border-radius: 8px;
        background-color: transparent;
        &:hover{
          background-color: #F1F1F1;
        }
      }
    }
  }




}

</style>


<style scoped>

</style>

